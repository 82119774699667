import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTimeFnsService } from '@src/app/core/services/date-time-fns.service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  getDate,
  getDay,
  getHours,
  getISODay,
  getISOWeek,
  getMinutes,
  getMonth,
  getSeconds,
  getWeek,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  set,
  setMonth,
  setYear,
  startOfDay,
  subMonths,
  subSeconds,
} from 'date-fns';

import { LocaleFnsConfig } from './daterangepicker.config';
import { DATE_FNS_FORMAT } from '@core/models/date.model';

export enum SideEnum {
  left = 'left',
  right = 'right',
}

@Component({
  selector: 'app-daterangepicker-material-fns',
  styleUrls: ['./daterangepicker-fns.component.scss'],
  templateUrl: './daterangepicker-fns.component.html',
  /* eslint-disable @angular-eslint/no-host-metadata-property */
  host: {
    '(click)': 'handleInternalClick($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerFnsComponent),
      multi: true,
    },
  ],
})
export class DaterangepickerFnsComponent implements OnInit {
  public fns = {
    convertFnsDateFormat: this.dateFnsService.convertFnsDateFormat,
    isValidDateString: this.dateFnsService.isValidDateString,
    format: this.dateFnsService.formatDate,
    isBefore,
    isAfter,
    getDate,
    getWeek,
    getISOWeek,
    getMonth,
  };
  private _old: { start: Date; end: Date } = { start: null, end: null };
  chosenLabel: string;
  public dateRangeTextPreview: string;
  calendarVariables: { left: any; right: any } = { left: {}, right: {} };
  timepickerVariables: { left: any; right: any } = { left: {}, right: {} };
  daterangepicker: { start: FormControl; end: FormControl } = { start: new FormControl(), end: new FormControl() };
  applyBtn: { disabled: boolean } = { disabled: false };
  startDate: Date = null;
  endDate: Date = null;
  @Input()
  dateLimit: number = null;
  // used in template for compile time support of enum values.
  sideEnum = SideEnum;

  @Input()
  minDate: Date = null;
  @Input()
  maxDate: Date = null;
  @Input()
  autoApply: boolean = false;
  @Input()
  singleDatePicker: boolean = false;
  @Input()
  showDropdowns: boolean = false;
  @Input()
  showWeekNumbers: boolean = false;
  @Input()
  showISOWeekNumbers: boolean = false;
  @Input()
  linkedCalendars: boolean = false;
  @Input()
  autoUpdateInput: boolean = true;
  @Input()
  alwaysShowCalendars: boolean = false;
  @Input()
  maxSpan: boolean = false;
  // timepicker variables
  @Input()
  timePicker: boolean = false;
  @Input()
  timePicker24Hour: boolean = false;
  @Input()
  timePickerIncrement: number = 1;
  @Input()
  timePickerSeconds: boolean = false;
  // end of timepicker variables
  @Input()
  showClearButton: boolean = false;
  @Input()
  firstMonthDayClass: string = null;
  @Input()
  lastMonthDayClass: string = null;
  @Input()
  emptyWeekRowClass: string = null;
  @Input()
  firstDayOfNextMonthClass: string = null;
  @Input()
  lastDayOfPreviousMonthClass: string = null;
  @Input()
  outputOnlySingleDate: boolean = false;
  _locale: LocaleFnsConfig = {};
  @Input() set locale(value) {
    this._locale = { ...this.dateFnsService.config, ...value };
  }
  get locale(): LocaleFnsConfig {
    return this._locale;
  }
  // custom ranges
  _ranges: { [key: string]: [Date, Date] } = {};

  @Input() set ranges(value) {
    this._ranges = value;
    this.renderRanges();
  }
  get ranges(): { [key: string]: [Date, Date] } {
    return this._ranges;
  }

  @Input()
  showCustomRangeLabel: boolean;
  @Input()
  showCancel: boolean = false;
  @Input()
  keepCalendarOpeningWithRange: boolean = false;
  @Input()
  showRangeLabelOnInput: boolean = false;
  chosenRange: string;
  rangesArray: Array<any> = [];

  // some state information
  isShown: boolean = false;
  inline: boolean = true;
  leftCalendar: any = {};
  rightCalendar: any = {};
  showCalInRanges: boolean = false;

  options: any = {}; // should get some opt from user
  @Input() drops: string;
  @Input() opens: string;
  @Input() classCustom: string = '';
  @Output() choosedDate: EventEmitter<Object>;
  @Output() rangeClicked: EventEmitter<Object>;
  @Output() datesUpdated: EventEmitter<Object | Date>;
  @ViewChild('pickerContainer', { static: true }) pickerContainer: ElementRef;
  @ViewChild('leftCalendar') leftCalendarElRef: ElementRef;
  @ViewChild('rightCalendar') rightCalendarElRef: ElementRef;
  public calendarSignleDateSelected: { row: number; col: number; date: Date } = null;

  constructor(
    private _ref: ChangeDetectorRef,
    public _changeDetectorRef: ChangeDetectorRef,
    public dateFnsService: DateTimeFnsService,
    @Inject(I18NEXT_SERVICE) private translate: ITranslationService
  ) {
    this.startDate = startOfDay(this.dateFnsService.currentDate);
    this.endDate = endOfDay(this.dateFnsService.currentDate);
    this.choosedDate = new EventEmitter();
    this.rangeClicked = new EventEmitter();
    this.datesUpdated = new EventEmitter();
    this.translate.events.languageChanged.subscribe(() => {
      this._buildLocale();
    });
  }

  ngOnInit() {
    this._buildLocale();
    const daysOfWeek = [...this.locale.daysOfWeek];
    if (this.locale.firstDay !== 0) {
      let iterator: number = this.locale.firstDay;

      while (iterator > 0) {
        daysOfWeek.push(daysOfWeek.shift());
        iterator--;
      }
    }
    this.locale.daysOfWeek = daysOfWeek;
    if (this.inline) {
      this._old.start = this.dateFnsService.cloneDate(this.startDate);
      this._old.end = this.dateFnsService.cloneDate(this.endDate);
    }
    this.calculateChosenLabel();
    this.reRenderCalendar();
  }

  reRenderCalendar() {
    this.updateMonthsInView();
    this.renderCalendar(SideEnum.left);
    this.renderCalendar(SideEnum.right);
    this.renderRanges();
    setTimeout(() => {
      this.calculateChosenLabel();
    }, 0);
  }

  renderRanges() {
    this.rangesArray = [];
    let start: Date, end: Date;
    if (typeof this.ranges === 'object') {
      for (const range in this.ranges) {
        if (this.ranges.hasOwnProperty(range)) {
          start = this.ranges[range][0];
          end = this.ranges[range][1];

          // If the start or end date exceed those allowed by the minDate or maxSpan
          // options, shorten the range to the allowable period.
          if (this.minDate && isBefore(start, this.minDate)) {
            start = this.dateFnsService.cloneDate(this.minDate);
          }

          let maxDate = this.maxDate;
          if (this.maxSpan && maxDate && isAfter(start, maxDate)) {
            // maxDate = start.clone().add(this.maxSpan); // why add a boolean value? this is return invalid date.
            maxDate = start;
          }
          if (maxDate && isAfter(end, maxDate)) {
            end = this.dateFnsService.cloneDate(maxDate);
          }

          // If the end of the range is before the minimum or the start of the range is
          // after the maximum, don't display this range option at all.
          if ((this.minDate && isBefore(end, this.minDate)) || (maxDate && isAfter(start, maxDate))) {
            continue;
          }

          // Support unicode chars in the range names.
          const elem = document.createElement('textarea');
          elem.innerHTML = range;
          const rangeHtml = elem.value;

          this.ranges[rangeHtml] = [start, end];
        }
      }
      for (const range in this.ranges) {
        if (this.ranges.hasOwnProperty(range)) {
          this.rangesArray.push(range);
        }
      }
      if (this.showCustomRangeLabel) {
        this.rangesArray.push(this.locale.customRangeLabel);
      }
      this.showCalInRanges = !this.rangesArray.length || this.alwaysShowCalendars;
      if (!this.timePicker) {
        this.startDate = startOfDay(this.startDate);
        this.endDate = endOfDay(this.endDate);

        this.startDate = startOfDay(new Date(this.startDate));
        this.endDate = endOfDay(new Date(this.endDate));
      }
      // can't be used together for now
      if (this.timePicker && this.autoApply) {
        this.autoApply = false;
      }
    }
  }
  renderTimePicker(side: SideEnum) {
    if (side === SideEnum.right && !this.endDate) {
      return;
    }
    let selected: Date, minDate: Date;
    const maxDate = this.maxDate;
    if (side === SideEnum.left) {
      selected = this.dateFnsService.cloneDate(this.startDate);
      minDate = this.minDate;
    } else if (side === SideEnum.right) {
      selected = this.dateFnsService.cloneDate(this.endDate);
      minDate = this.startDate;
    }
    const start = this.timePicker24Hour ? 0 : 1;
    const end = this.timePicker24Hour ? 23 : 12;
    this.timepickerVariables[side] = {
      hours: [],
      minutes: [],
      minutesLabel: [],
      seconds: [],
      secondsLabel: [],
      disabledHours: [],
      disabledMinutes: [],
      disabledSeconds: [],
      selectedHour: 0,
      selectedMinute: 0,
      selectedSecond: 0,
    };
    // generate hours
    for (let i = start; i <= end; i++) {
      let i_in_24 = i;
      if (!this.timePicker24Hour) {
        i_in_24 = getHours(selected) >= 12 ? (i === 12 ? 12 : i + 12) : i === 12 ? 0 : i;
      }

      const time: Date = this.dateFnsService.cloneDate(selected);
      time.setHours(i_in_24);
      let disabled = false;
      if (minDate && isBefore(time.setMinutes(59), minDate)) {
        disabled = true;
      }
      if (maxDate && isAfter(time.setMinutes(0), maxDate)) {
        disabled = true;
      }

      this.timepickerVariables[side].hours.push(i);
      if (i_in_24 === getHours(selected) && !disabled) {
        this.timepickerVariables[side].selectedHour = i;
      } else if (disabled) {
        this.timepickerVariables[side].disabledHours.push(i);
      }
    }
    // generate minutes
    for (let i = 0; i < 60; i += this.timePickerIncrement) {
      const padded = i < 10 ? '0' + i : i;
      const time: Date = this.dateFnsService.cloneDate(selected);
      time.setMinutes(i);

      let disabled = false;
      if (minDate && isBefore(time.setSeconds(59), minDate)) {
        disabled = true;
      }
      if (maxDate && isAfter(time.setSeconds(0), maxDate)) {
        disabled = true;
      }
      this.timepickerVariables[side].minutes.push(i);
      this.timepickerVariables[side].minutesLabel.push(padded);
      if (getMinutes(selected) === i && !disabled) {
        this.timepickerVariables[side].selectedMinute = i;
      } else if (disabled) {
        this.timepickerVariables[side].disabledMinutes.push(i);
      }
    }
    // generate seconds
    if (this.timePickerSeconds) {
      for (let i = 0; i < 60; i++) {
        const padded = i < 10 ? '0' + i : i;
        const time = this.dateFnsService.cloneDate(selected);
        time.setSeconds(i);

        let disabled = false;
        if (minDate && isBefore(time, minDate)) {
          disabled = true;
        }
        if (maxDate && isAfter(time, maxDate)) {
          disabled = true;
        }

        this.timepickerVariables[side].seconds.push(i);
        this.timepickerVariables[side].secondsLabel.push(padded);
        if (getSeconds(selected) === i && !disabled) {
          this.timepickerVariables[side].selectedSecond = i;
        } else if (disabled) {
          this.timepickerVariables[side].disabledSeconds.push(i);
        }
      }
    }
    // generate AM/PM
    if (!this.timePicker24Hour) {
      const timeSelected = this.dateFnsService.cloneDate(selected);
      timeSelected.setHours(12, 0, 0);
      if (minDate && isBefore(timeSelected, minDate)) {
        this.timepickerVariables[side].amDisabled = true;
      }

      const timeSelected2 = this.dateFnsService.cloneDate(selected);
      timeSelected2.setHours(0, 0, 0);

      if (maxDate && isAfter(timeSelected2, maxDate)) {
        this.timepickerVariables[side].pmDisabled = true;
      }
      if (getHours(selected) >= 12) {
        this.timepickerVariables[side].ampmModel = 'PM';
      } else {
        this.timepickerVariables[side].ampmModel = 'AM';
      }
    }
    this.timepickerVariables[side].selected = selected;
  }
  renderCalendar(side: SideEnum) {
    // side enum
    const mainCalendar: any = side === SideEnum.left ? this.leftCalendar : this.rightCalendar;
    const calendarMonth: Date = mainCalendar.month as Date;
    const month = getMonth(calendarMonth);
    const year = getYear(calendarMonth);
    const hour = getHours(calendarMonth);
    const minute = getMinutes(calendarMonth);
    const second = getSeconds(calendarMonth);

    const daysInMonth = this.dateFnsService.getDaysInMonth(year, month);
    const firstDay = this.dateFnsService.getNthDayOfMonth(1, year, month);
    const lastDay = this.dateFnsService.getLastDateOfMonth(year, month);
    const lastMonth = month - 1;
    const lastYear = year - 1;
    const daysInLastMonth = this.dateFnsService.getDaysInMonth(year, lastMonth);

    const dayOfWeek = getDay(firstDay); //  Get the day of the week of the given date.
    // initialize a 6 rows x 7 columns array for the calendar
    const calendar: any = [];
    calendar.firstDay = firstDay;
    calendar.lastDay = lastDay;

    for (let i = 0; i < 6; i++) {
      calendar[i] = [];
    }

    // populate the calendar with date objects
    let startDay = daysInLastMonth - dayOfWeek + this.locale.firstDay + 1;
    if (startDay > daysInLastMonth) {
      startDay -= 7;
    }

    if (dayOfWeek === this.locale.firstDay) {
      startDay = daysInLastMonth - 6;
    }

    let curDate = new Date(year, lastMonth, startDay, 12, minute, second);

    for (let i = 0, col = 0, row = 0; i < 42; i++, col++, curDate = addHours(curDate, 24)) {
      if (i > 0 && col % 7 === 0) {
        col = 0;
        row++;
      }
      calendar[row][col] = this.dateFnsService.addTime(this.dateFnsService.cloneDate(curDate), hour, minute, second);
      const formatDate = 'yyyy-MM-dd';

      if (
        this.minDate &&
        side === 'left' &&
        this.dateFnsService.formatDate(calendar[row][col], formatDate) === this.dateFnsService.formatDate(this.minDate, formatDate) &&
        isBefore(calendar[row][col], this.minDate)
      ) {
        calendar[row][col] = this.dateFnsService.cloneDate(this.minDate);
      }

      if (
        this.maxDate &&
        side === 'right' &&
        this.dateFnsService.formatDate(calendar[row][col], formatDate) === this.dateFnsService.formatDate(this.maxDate, formatDate) &&
        isAfter(calendar[row][col], this.maxDate)
      ) {
        calendar[row][col] = this.dateFnsService.cloneDate(this.maxDate);
      }
    }

    // make the calendar object available to hoverDate/clickDate
    if (side === SideEnum.left) {
      this.leftCalendar.calendar = calendar;
    } else {
      this.rightCalendar.calendar = calendar;
    }
    //
    // Display the calendar
    //
    const minDate = side === 'left' ? this.minDate : this.startDate;
    let maxDate = this.maxDate;
    // adjust maxDate to reflect the dateLimit setting in order to
    // grey out end dates beyond the dateLimit
    if (this.endDate === null && this.dateLimit) {
      // const maxLimit = this.startDate.clone().add(this.dateLimit, 'day').endOf('day');
      const maxLimit = endOfDay(addDays(this.dateFnsService.cloneDate(this.startDate), this.dateLimit));
      if (!maxDate || isBefore(maxLimit, maxDate)) {
        maxDate = maxLimit;
      }
    }
    this.calendarVariables[side] = {
      month: month,
      year: year,
      hour: hour,
      minute: minute,
      second: second,
      daysInMonth: daysInMonth,
      firstDay: firstDay,
      lastDay: lastDay,
      lastMonth: lastMonth,
      lastYear: lastYear,
      daysInLastMonth: daysInLastMonth,
      dayOfWeek: dayOfWeek,
      // other vars
      calRows: Array.from(Array(6).keys()),
      calCols: Array.from(Array(7).keys()),
      classes: {},
      minDate: minDate,
      maxDate: maxDate,
      calendar: calendar,
    };
    if (this.showDropdowns) {
      const currentMonth = getMonth(calendar[1][1]);
      const currentYear = getYear(calendar[1][1]);
      const maxYear = (maxDate && getYear(maxDate)) || currentYear + 1;
      const minYear = (minDate && getYear(minDate)) || currentYear - 3;
      const inMinYear = currentYear === minYear;
      const inMaxYear = currentYear === maxYear;
      const years = [];
      for (let y = minYear; y <= maxYear; y++) {
        years.push(y);
      }
      this.calendarVariables[side].dropdowns = {
        currentMonth: currentMonth,
        currentYear: currentYear,
        maxYear: maxYear,
        minYear: minYear,
        inMinYear: inMinYear,
        inMaxYear: inMaxYear,
        monthArrays: Array.from(Array(12).keys()),
        yearArrays: years,
      };
    }
    this._buildCells(calendar, side);
  }

  setStartDate(startDate: Date | string) {
    if (typeof startDate === 'string') {
      this.startDate = parse(startDate, DATE_FNS_FORMAT, new Date());
    }

    if (typeof startDate === 'object') {
      this.startDate = startDate;
    }
    if (!this.timePicker) {
      this.startDate = startOfDay(this.startDate);
    }

    if (this.timePicker && this.timePickerIncrement) {
      // this.startDate.minute(Math.round(this.startDate.minute() / this.timePickerIncrement) * this.timePickerIncrement);
      this.startDate.setMinutes(Math.round(getMinutes(this.startDate) / this.timePickerIncrement) * this.timePickerIncrement);
    }

    if (this.minDate && isBefore(this.startDate, this.minDate)) {
      this.startDate = this.dateFnsService.cloneDate(this.minDate);
      if (this.timePicker && this.timePickerIncrement) {
        this.startDate.setMinutes(Math.round(getMinutes(this.startDate) / this.timePickerIncrement) * this.timePickerIncrement);
      }
    }

    if (this.maxDate && isAfter(this.startDate, this.maxDate)) {
      this.startDate = this.dateFnsService.cloneDate(this.maxDate);
      if (this.timePicker && this.timePickerIncrement) {
        this.startDate.setMinutes(Math.floor(getMinutes(this.startDate) / this.timePickerIncrement) * this.timePickerIncrement);
      }
    }

    if (!this.isShown) {
      this.updateElement();
    }

    this.updateMonthsInView();
  }

  setEndDate(endDate: Date | string) {
    if (typeof endDate === 'string') {
      this.endDate = parse(endDate, DATE_FNS_FORMAT, new Date());
    }
    if (typeof endDate === 'object') {
      this.endDate = endDate;
    }
    if (!this.timePicker) {
      // this.endDate = this.endDate.add(1, 'd').startOf('day').subtract(1, 'second');
      this.endDate = subSeconds(startOfDay(addDays(this.endDate, 1)), 1);
    }

    if (this.timePicker && this.timePickerIncrement) {
      this.endDate.setMinutes(Math.round(getMinutes(this.endDate) / this.timePickerIncrement) * this.timePickerIncrement);
    }

    if (isBefore(this.endDate, this.startDate)) {
      this.endDate = this.dateFnsService.cloneDate(this.startDate);
    }

    if (this.maxDate && isAfter(this.endDate, this.maxDate)) {
      this.endDate = this.dateFnsService.cloneDate(this.maxDate);
    }
    // this.startDate.clone().add(this.dateLimit, 'day').isBefore(this.endDate)
    if (this.dateLimit && isBefore(addDays(this.dateFnsService.cloneDate(this.startDate), this.dateLimit), this.endDate)) {
      this.endDate = addDays(this.dateFnsService.cloneDate(this.startDate), this.dateLimit);
    }

    if (!this.isShown) {
      this.updateElement();
    }
    this.updateMonthsInView();
  }

  updateView() {
    if (this.timePicker) {
      this.renderTimePicker(SideEnum.left);
      this.renderTimePicker(SideEnum.right);
    }
    this.updateMonthsInView();
    this.updateCalendars();
  }

  updateMonthsInView() {
    const format = 'yyyy-MM';
    if (this.endDate) {
      // if both dates are visible already, do nothing
      if (
        !this.singleDatePicker &&
        this.leftCalendar.month &&
        this.rightCalendar.month &&
        ((this.startDate &&
          this.leftCalendar &&
          this.dateFnsService.formatDate(this.startDate, format) === this.dateFnsService.formatDate(this.leftCalendar.month, format)) ||
          (this.startDate &&
            this.rightCalendar &&
            this.dateFnsService.formatDate(this.startDate, format) === this.dateFnsService.formatDate(this.rightCalendar.month, format))) &&
        (this.dateFnsService.formatDate(this.endDate, format) === this.dateFnsService.formatDate(this.leftCalendar.month, format) ||
          this.dateFnsService.formatDate(this.endDate, format) === this.dateFnsService.formatDate(this.rightCalendar.month, format))
      ) {
        return;
      }
      if (this.startDate) {
        // Gets or sets the day of the month. -> get 2nd day of month
        this.leftCalendar.month = this.dateFnsService.getNthDayOfMonth(1, this.startDate);
        if (
          !this.linkedCalendars &&
          (getMonth(this.endDate) !== getMonth(this.startDate) || getYear(this.endDate) !== getYear(this.startDate))
        ) {
          this.rightCalendar.month = this.dateFnsService.getNthDayOfMonth(1, this.endDate);
        } else {
          this.rightCalendar.month = addMonths(this.dateFnsService.getNthDayOfMonth(1, this.startDate), 1);
        }
      }
    } else {
      const startDate = this.startDate || new Date();
      if (
        this.dateFnsService.formatDate(this.leftCalendar.month, format) !== this.dateFnsService.formatDate(startDate, format) &&
        this.dateFnsService.formatDate(this.rightCalendar.month, format) !== this.dateFnsService.formatDate(startDate, format)
      ) {
        this.leftCalendar.month = this.dateFnsService.getNthDayOfMonth(1, startDate);
        this.rightCalendar.month = addMonths(this.dateFnsService.getNthDayOfMonth(1, startDate), 1);
      }
    }
    if (this.maxDate && this.linkedCalendars && !this.singleDatePicker && this.rightCalendar.month > this.maxDate) {
      this.rightCalendar.month = this.dateFnsService.getNthDayOfMonth(1, this.maxDate);
      this.leftCalendar.month = subMonths(this.dateFnsService.getNthDayOfMonth(1, this.maxDate), 1);
    }
  }
  /**
   *  This is responsible for updating the calendars
   */
  updateCalendars() {
    this.renderCalendar(SideEnum.left);
    this.renderCalendar(SideEnum.right);
    if (this.endDate === null) {
      return;
    }
  }
  updateElement() {
    if (!this.singleDatePicker && this.autoUpdateInput) {
      if (this.startDate && this.endDate) {
        // if we use ranges and should show range label on inpu
        if (
          this.rangesArray.length &&
          this.showRangeLabelOnInput === true &&
          this.chosenRange &&
          this.locale.customRangeLabel !== this.chosenRange
        ) {
          this.chosenLabel = this.chosenRange;
        } else {
          this.chosenLabel =
            this.dateFnsService.formatDate(this.startDate, this.locale.format) +
            this.locale.separator +
            this.dateFnsService.formatDate(this.endDate, this.locale.format);
        }
      }
    } else if (this.autoUpdateInput) {
      this.chosenLabel = this.dateFnsService.formatDate(this.startDate || new Date(), this.locale.format);
    }
  }

  remove() {
    this.isShown = false;
  }
  /**
   * this should calculate the label
   */
  calculateChosenLabel(chosenRange?) {
    if (!this.locale || !this.locale.separator) {
      this._buildLocale();
    }
    if (chosenRange) {
      this.chosenRange = chosenRange;
      return;
    }
    let customRange = true;
    let i = 0;
    if (this.rangesArray.length > 0) {
      for (const range in this.ranges) {
        if (this.ranges.hasOwnProperty(range)) {
          if (this.timePicker) {
            const format = this.timePickerSeconds ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd HH:mm';
            // ignore times when comparing dates if time picker seconds is not enabled
            if (
              this.dateFnsService.formatDate(this.startDate, format) === this.dateFnsService.formatDate(this.ranges[range][0], format) &&
              this.dateFnsService.formatDate(this.endDate, format) === this.dateFnsService.formatDate(this.ranges[range][1], format)
            ) {
              customRange = false;
              this.chosenRange = this.rangesArray[i];
              break;
            }
          } else {
            // ignore times when comparing dates if time picker is not enabled
            const format = 'yyyy-MM-dd';
            if (
              this.dateFnsService.formatDate(this.startDate, format) === this.dateFnsService.formatDate(this.ranges[range][0], format) &&
              this.dateFnsService.formatDate(this.endDate, format) === this.dateFnsService.formatDate(this.ranges[range][1], format)
            ) {
              customRange = false;
              this.chosenRange = this.rangesArray[i];
              break;
            }
          }

          i++;
        }
      }
      if (customRange) {
        if (this.showCustomRangeLabel) {
          this.chosenRange = this.locale.customRangeLabel;
        } else {
          this.chosenRange = null;
        }
        // if custom label: show calenar
        this.showCalInRanges = true;
      }
    }

    this.updateElement();
  }

  clickApply(e?) {
    // test if startDate or endDate label is different from startDate or endDate
    if (!this.singleDatePicker && this.startDate && !this.endDate) {
      this.endDate = this.dateFnsService.cloneDate(this.startDate);
      // this.calculateChosenLabel();
    }
    this.calculateChosenLabel();
    if (this.chosenLabel) {
      this.choosedDate.emit({ chosenLabel: this.chosenLabel, startDate: this.startDate, endDate: this.endDate });
    }
    if (this.outputOnlySingleDate) {
      this.datesUpdated.emit(this.startDate);
    } else {
      this.datesUpdated.emit({ label: this.chosenRange, startDate: this.startDate, endDate: this.endDate });
    }
    this.hide(undefined, true);
  }

  clickCancel(e) {
    this.startDate = this._old.start;
    this.endDate = this._old.end;
    if (this.inline) {
      this.updateView();
    }
    this.hide();
  }
  /**
   * called when month is changed
   * @param monthEvent get value in event.target.value
   * @param side left or right
   */
  monthChanged(monthEvent: any, side: SideEnum) {
    const year = this.calendarVariables[side].dropdowns.currentYear;
    const month = parseInt(monthEvent.target.value, 10);
    this.monthOrYearChanged(month, year, side);
  }
  /**
   * called when year is changed
   * @param yearEvent get value in event.target.value
   * @param side left or right
   */
  yearChanged(yearEvent: any, side: SideEnum) {
    const month = this.calendarVariables[side].dropdowns.currentMonth;
    const year = parseInt(yearEvent.target.value, 10);
    this.monthOrYearChanged(month, year, side);
  }
  /**
   * called when time is changed
   * @param timeEvent  an event
   * @param side left or right
   */
  timeChanged(timeEvent: any, side: SideEnum, key: string) {
    if (key !== 'ampmModel') {
      this.timepickerVariables[side][key] = coerceNumberProperty(timeEvent, 7);
    } else {
      this.timepickerVariables[side].ampmModel = timeEvent.toString() || 'AM';
    }
    let hour = parseInt(this.timepickerVariables[side].selectedHour, 10);
    const minute = parseInt(this.timepickerVariables[side].selectedMinute, 10);
    const second = this.timePickerSeconds ? parseInt(this.timepickerVariables[side].selectedSecond, 10) : 0;

    if (!this.timePicker24Hour) {
      const ampm = this.timepickerVariables[side].ampmModel;
      if (ampm === 'PM' && hour < 12) {
        hour += 12;
      }
      if (ampm === 'AM' && hour === 12) {
        hour = 0;
      }
    }

    if (side === SideEnum.left) {
      const start = this.dateFnsService.cloneDate(this.startDate);
      start.setHours(hour, minute, second);
      this.setStartDate(start);
      if (this.singleDatePicker) {
        this.endDate = this.dateFnsService.cloneDate(this.startDate);
      } else if (
        this.endDate &&
        this.dateFnsService.formatDate(this.endDate, 'yyyy-MM-dd') === this.dateFnsService.formatDate(start, 'yyyy-MM-dd') &&
        isBefore(this.endDate, start)
      ) {
        this.setEndDate(start);
      }
    } else if (this.endDate) {
      const end = this.dateFnsService.cloneDate(this.endDate);
      end.setHours(hour, minute, second);

      this.setEndDate(end);
    }

    // update the calendars so all clickable dates reflect the new time component
    this.updateCalendars();

    // re-render the time pickers because changing one selection can affect what's enabled in another
    this.renderTimePicker(SideEnum.left);
    this.renderTimePicker(SideEnum.right);
  }
  /**
   *  call when month or year changed
   * @param month month number 0 -11
   * @param year year eg: 1995
   * @param side left or right
   */
  monthOrYearChanged(month: number, year: number, side: SideEnum) {
    const isLeft = side === SideEnum.left;

    if (!isLeft) {
      const startDateInstance = typeof this.startDate === 'string' ? new Date(this.startDate) : this.startDate;
      if (year < startDateInstance.getFullYear() || (year === startDateInstance.getFullYear() && month < startDateInstance.getMonth())) {
        month = startDateInstance.getMonth();
        year = startDateInstance.getFullYear();
      }
    }

    if (this.minDate) {
      const minDateInstance = typeof this.minDate === 'string' ? new Date(this.minDate) : this.minDate;
      if (year < minDateInstance.getFullYear() || (year === minDateInstance.getFullYear() && month < minDateInstance.getMonth())) {
        month = minDateInstance.getMonth();
        year = minDateInstance.getFullYear();
      }
    }

    if (this.maxDate) {
      const maxDateInstance = typeof this.maxDate === 'string' ? new Date(this.maxDate) : this.maxDate;
      if (year > maxDateInstance.getFullYear() || (year === maxDateInstance.getFullYear() && month > maxDateInstance.getMonth())) {
        month = maxDateInstance.getMonth();
        year = maxDateInstance.getFullYear();
      }
    }
    this.calendarVariables[side].dropdowns.currentYear = year;
    this.calendarVariables[side].dropdowns.currentMonth = month;
    if (isLeft) {
      this.leftCalendar.month = set(this.leftCalendar.month, { year, month });
      if (this.linkedCalendars) {
        this.rightCalendar.month = addMonths(this.dateFnsService.cloneDate(this.leftCalendar.month), 1);
      }
    } else {
      this.rightCalendar.month = set(this.leftCalendar.month, { year, month });
      if (this.linkedCalendars) {
        this.leftCalendar.month = subMonths(this.dateFnsService.cloneDate(this.rightCalendar.month), 1);
      }
    }
    this.updateCalendars();
  }

  /**
   * Click on previous month
   * @param side left or right calendar
   */
  clickPrev(side: SideEnum) {
    if (side === SideEnum.left) {
      this.leftCalendar.month = subMonths(this.leftCalendar.month, 1);
      if (this.linkedCalendars) {
        this.rightCalendar.month = subMonths(this.rightCalendar.month, 1);
      }
    } else {
      this.rightCalendar.month = subMonths(this.rightCalendar.month, 1);
    }
    this.updateCalendars();
  }
  /**
   * Click on next month
   * @param side left or right calendar
   */
  clickNext(side: SideEnum) {
    if (side === SideEnum.left) {
      // this.leftCalendar.month.add(1, 'month');
      this.leftCalendar.month = addMonths(this.leftCalendar.month, 1);
    } else {
      this.rightCalendar.month = addMonths(this.rightCalendar.month, 1);
      if (this.linkedCalendars) {
        this.leftCalendar.month = addMonths(this.leftCalendar.month, 1);
      }
    }
    this.updateCalendars();
  }
  /**
   * When selecting a date
   * @param e event: get value by e.target.value
   * @param side left or right
   * @param row row position of the current date clicked
   * @param col col position of the current date clicked
   */
  clickDate(e, side: SideEnum, row: number, col: number) {
    if (e.target.tagName === 'TD') {
      if (!e.target.classList.contains('available')) {
        return;
      }
    } else if (e.target.tagName === 'SPAN') {
      if (!e.target.parentElement.classList.contains('available')) {
        return;
      }
    }
    // set selected style
    if (this.singleDatePicker) {
      this.calendarSignleDateSelected = {
        row: coerceNumberProperty(row),
        col: coerceNumberProperty(col),
        date: this.calendarVariables[side].calendar[row][col],
      };
    }
    if (this.rangesArray.length) {
      this.chosenRange = this.locale.customRangeLabel;
    }

    let date: Date = side === SideEnum.left ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col];

    if (this.endDate || isBefore(date, this.startDate)) {
      // picking start
      if (this.timePicker) {
        date = this._getDateWithTime(date, SideEnum.left);
      }
      this.endDate = null;
      this.setStartDate(date);
    } else if (!this.endDate && isBefore(date, this.startDate)) {
      // special case: clicking the same date for start/end,
      // but the time of the end date is before the start date
      this.setEndDate(this.startDate);
    } else {
      // picking end
      if (this.timePicker) {
        date = this._getDateWithTime(date, SideEnum.right);
      }
      this.setEndDate(date);
      if (this.autoApply) {
        // this.calculateChosenLabel();
        this.clickApply();
      }
    }

    if (this.singleDatePicker) {
      this.setEndDate(this.startDate);
      this.updateElement();
      if (this.autoApply) {
        this.clickApply();
      }
    }

    this.updateView();
    this.calculateTextPreview();
    // This is to cancel the blur event handler if the mouse was in one of the inputs
    e.stopPropagation();
  }
  /**
   *  Click on the custom range
   * @param e: Event
   * @param label
   */
  clickRange(e, label) {
    this.chosenRange = label;
    if (label === this.locale.customRangeLabel) {
      this.isShown = true; // show calendars
      this.showCalInRanges = true;
    } else {
      const dates: Date[] = this.ranges[label];
      this.startDate = this.dateFnsService.cloneDate(dates[0]);
      this.endDate = this.dateFnsService.cloneDate(dates[1]);
      if (this.showRangeLabelOnInput && label !== this.locale.customRangeLabel) {
        this.chosenLabel = label;
      } else {
        this.calculateChosenLabel();
      }
      this.showCalInRanges = !this.rangesArray.length || this.alwaysShowCalendars;

      if (!this.timePicker) {
        this.startDate = startOfDay(this.startDate);
        this.endDate = endOfDay(this.endDate);
      }

      if (!this.alwaysShowCalendars) {
        this.isShown = false; // hide calendars
      }
      this.rangeClicked.emit({ label: label, dates: dates });
      if (!this.keepCalendarOpeningWithRange) {
        this.clickApply();
      } else {
        this.leftCalendar.month = setMonth(this.leftCalendar.month, dates[0].getMonth());
        this.leftCalendar.month = setYear(this.leftCalendar.month, dates[0].getFullYear());
        this.rightCalendar.month = setMonth(this.leftCalendar.month, dates[1].getMonth());
        this.rightCalendar.month = setYear(this.leftCalendar.month, dates[0].getFullYear());
        this.updateCalendars();
        if (this.timePicker) {
          this.renderTimePicker(SideEnum.left);
          this.renderTimePicker(SideEnum.right);
        }
      }
    }
  }

  show(e?) {
    if (this.isShown) {
      return;
    }
    this._old.start = this.dateFnsService.cloneDate(this.startDate);
    this._old.end = this.dateFnsService.cloneDate(this.endDate);
    this.isShown = true;
    this.updateView();
  }

  hide(e?, applied?) {
    if (!this.isShown) {
      return;
    }
    // incomplete date selection, revert to last values
    if (!this.endDate || !applied) {
      if (this._old.start) {
        this.startDate = this.dateFnsService.cloneDate(this._old.start);
      }
      if (this._old.end) {
        this.endDate = this.dateFnsService.cloneDate(this._old.end);
      }
    }

    // if a new date range was selected, invoke the user callback function
    if (!isSameDay(this.startDate, this._old.start) || !isSameDay(this.endDate, this._old.end)) {
      // this.callback(this.startDate, this.endDate, this.chosenLabel);
    }

    // if picker is attached to a text input, update it
    this.updateElement();
    this.isShown = false;
    this._ref.detectChanges();
  }

  /**
   * handle click on all element in the component, usefull for outside of click
   * @param e event
   */
  handleInternalClick(e) {
    e.stopPropagation();
  }
  /**
   * update the locale options
   * @param locale
   */
  updateLocale(locale) {
    for (const key in locale) {
      if (locale.hasOwnProperty(key)) {
        this.locale[key] = locale[key];
      }
    }
  }
  /**
   *  clear the daterange picker
   */
  clear() {
    if (!this.showClearButton) {
      return;
    }
    this.startDate = startOfDay(Date.now());
    this.endDate = endOfDay(Date.now());
    this.chosenRange = null;
    this.choosedDate.emit({ chosenLabel: '', startDate: null, endDate: null });
    this.datesUpdated.emit({ startDate: null, endDate: null });
    this.hide();
  }

  /**
   * Find out if the selected range should be disabled if it doesn't
   * fit into minDate and maxDate limitations.
   */
  disableRange(range) {
    if (range === this.locale.customRangeLabel) {
      return false;
    }
    const rangeMarkers = this.ranges[range];
    const areBothBefore = rangeMarkers.every((date: Date) => {
      if (!this.minDate) {
        return false;
      }
      return isBefore(date, this.minDate);
    });

    const areBothAfter = rangeMarkers.every((date: Date) => {
      if (!this.maxDate) {
        return false;
      }
      return isAfter(date, this.maxDate);
    });
    return areBothBefore || areBothAfter;
  }
  /**
   *
   * @param date the date to add time
   * @param side left or right
   */
  private _getDateWithTime(date: Date, side: SideEnum): Date {
    let hour = parseInt(this.timepickerVariables[side].selectedHour, 10);
    if (!this.timePicker24Hour) {
      const ampm = this.timepickerVariables[side].ampmModel;
      if (ampm === 'PM' && hour < 12) {
        hour += 12;
      }
      if (ampm === 'AM' && hour === 12) {
        hour = 0;
      }
    }
    const minute = parseInt(this.timepickerVariables[side].selectedMinute, 10);
    const second = this.timePickerSeconds ? parseInt(this.timepickerVariables[side].selectedSecond, 10) : 0;

    date.setHours(hour, minute, second);

    return date;
  }
  /**
   *  build the locale config
   */
  private _buildLocale() {
    this.locale = { ...this.dateFnsService.config, ...this.locale };
    if (!this.locale.format) {
      if (this.timePicker) {
        this.locale.format = this.dateFnsService.getLocalFormat(true);
      } else {
        this.locale.format = this.dateFnsService.getLocalFormat();
      }
    }
  }

  private _buildCells(calendar, side: SideEnum) {
    for (let row = 0; row < 6; row++) {
      this.calendarVariables[side].classes[row] = {};
      const rowClasses = [];
      if (this.emptyWeekRowClass && !this.hasCurrentMonthDays(this.calendarVariables[side].month, calendar[row])) {
        rowClasses.push(this.emptyWeekRowClass);
      }
      for (let col = 0; col < 7; col++) {
        const classes = [];
        // highlight today's date
        if (isSameDay(new Date(), calendar[row][col])) {
          classes.push('today');
        }
        // highlight weekends
        if (getISODay(calendar[row][col]) > 5) {
          classes.push('weekend');
        }
        // grey out the dates in other months displayed at beginning and end of this calendar
        if (getMonth(calendar[row][col]) !== getMonth(calendar[1][1])) {
          classes.push('off');

          // mark the last day of the previous month in this calendar
          if (
            this.lastDayOfPreviousMonthClass &&
            (getMonth(calendar[row][col]) < getMonth(calendar[1][1]) || getMonth(calendar[1][1]) === 0) &&
            getDate(calendar[row][col]) === this.calendarVariables[side].daysInLastMonth
          ) {
            classes.push(this.lastDayOfPreviousMonthClass);
          }

          // mark the first day of the next month in this calendar
          if (
            this.firstDayOfNextMonthClass &&
            (getMonth(calendar[row][col]) > getMonth(calendar[1][1]) || getMonth(calendar[row][col]) === 0) &&
            getDate(calendar[row][col]) === 1
          ) {
            classes.push(this.firstDayOfNextMonthClass);
          }
        }
        // mark the first day of the current month with a custom class
        if (
          this.firstMonthDayClass &&
          getMonth(calendar[row][col]) === getMonth(calendar[1][1]) &&
          getDate(calendar[row][col]) === getDate(calendar.firstDay)
        ) {
          classes.push(this.firstMonthDayClass);
        }
        // mark the last day of the current month with a custom class
        if (
          this.lastMonthDayClass &&
          getMonth(calendar[row][col]) === getMonth(calendar[1][1]) &&
          getDate(calendar[row][col]) === getDate(calendar.lastDay)
        ) {
          classes.push(this.lastMonthDayClass);
        }
        // don't allow selection of dates before the minimum date
        if (this.minDate && isBefore(calendar[row][col], this.minDate)) {
          classes.push('off', 'disabled');
        }
        // don't allow selection of dates after the maximum date
        if (this.calendarVariables[side].maxDate && isAfter(calendar[row][col], this.calendarVariables[side].maxDate)) {
          classes.push('off', 'disabled');
        }
        // highlight the currently selected start date
        if (
          this.chosenRange &&
          this.startDate &&
          this.dateFnsService.formatDate(calendar[row][col], 'yyyy-MM-dd') === this.dateFnsService.formatDate(this.startDate, 'yyyy-MM-dd')
        ) {
          classes.push('active', 'start-date');
        }
        // highlight the currently selected end date
        if (
          this.chosenRange &&
          this.endDate != null &&
          this.dateFnsService.formatDate(calendar[row][col], 'yyyy-MM-dd') === this.dateFnsService.formatDate(this.endDate, 'yyyy-MM-dd')
        ) {
          classes.push('active', 'end-date');
        }
        // highlight dates in-between the selected dates
        if (this.chosenRange && this.endDate != null && calendar[row][col] > this.startDate && calendar[row][col] < this.endDate) {
          classes.push('in-range');
        }
        // store classes var
        let cname = '',
          disabled = false;
        for (let i = 0; i < classes.length; i++) {
          cname += classes[i] + ' ';
          if (classes[i] === 'disabled') {
            disabled = true;
          }
        }
        if (!disabled) {
          cname += 'available';
        }
        this.calendarVariables[side].classes[row][col] = cname.replace(/^\s+|\s+$/g, '');
      }
      const allItemClasses = Object.values(this.calendarVariables[side].classes[row] as string);

      if (allItemClasses.every((classStr) => classStr.split(' ').includes('off') && !this.singleDatePicker)) {
        rowClasses.push('hidden-week');
      }
      this.calendarVariables[side].classes[row].classList = rowClasses.join(' ');
    }
    this.calculateTextPreview();
  }

  /**
   * Find out if the current calendar row has current month days
   * (as opposed to consisting of only previous/next month days)
   */
  hasCurrentMonthDays(currentMonth, row) {
    for (let day = 0; day < 7; day++) {
      if (getMonth(row[day]) === currentMonth) {
        return true;
      }
    }
    return false;
  }

  calculateTextPreview() {
    const format = this.locale.format;
    const resultTrue = () => `
      ${this.dateFnsService.formatDate(this.startDate, format)}
      ${this.locale.separator}
      ${this.dateFnsService.formatDate(this.endDate, format)}
    `;
    const validStartDate = this.startDate && this.dateFnsService.isValidDateString(this.startDate.toString());
    const validEndDate = this.endDate && this.dateFnsService.isValidDateString(this.endDate.toString());
    this.dateRangeTextPreview =
      !validStartDate && !validEndDate
        ? this.dateFnsService.formatDate(new Date(), format)
        : this.endDate && this.dateFnsService.formatDate(this.endDate, format) !== this.dateFnsService.formatDate(this.startDate, format)
          ? resultTrue()
          : this.dateFnsService.formatDate(this.startDate, format);
  }

  isSingleDateSelected(side: SideEnum, row: number, col: number): boolean {
    const dateToCompare = this.calendarVariables[side]?.calendar?.[row]?.[col]
      ? this.dateFnsService.formatDate(this.calendarVariables[side].calendar[row][col])
      : '';
    const selected = this.calendarSignleDateSelected?.date ? this.dateFnsService.formatDate(this.calendarSignleDateSelected?.date) : '-';

    return this.singleDatePicker && dateToCompare === selected;
  }

  isBefore(date: string | Date | number, targetDate: string | number | Date) {
    const dateInstance = typeof date === 'string' ? new Date(date) : date;
    const targetDateInstance = typeof targetDate === 'string' ? new Date(targetDate) : targetDate;

    return this.fns.isBefore(dateInstance, targetDateInstance);
  }
}
